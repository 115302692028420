import React from "react"
import { Helmet } from "react-helmet"

import FontStyles from "../components/FontStyles"
import GlobalStyles from "../components/GlobalStyles"

import Cookie from "../components/Cookie"
import Footer from "../components/Footer"
import Header from "../components/Header"

const CookiePolicy = () => {
  return (
    <>
      <FontStyles />
      <GlobalStyles />
      <Helmet>
        <title>ASD Yuan - Cookie Policy</title>
        <meta name="description" content="Cookie Policy del sito." />
        <link rel="canonical" href="https://www.taichiyuan.it/cookie-policy/" />
      </Helmet>
      <Header />
      <Cookie />
      <Footer />
    </>
  )
}

export default CookiePolicy
