/* eslint-disable react/no-children-prop */
import React from "react"
import Markdown from "react-markdown"
import styled from "styled-components/macro"
import { useStaticQuery, graphql } from "gatsby"
import { LinkRenderer, HeadingRenderer } from "../../utils/markdownUtils"

import { QUERY, COLORS } from "../../constants"

const Cookie = () => {
  const data = useStaticQuery(graphql`
    query CookieQuery {
      strapiCookiePolicy {
        titolo
        testo
      }
    }
  `)

  const { titolo, testo } = data.strapiCookiePolicy

  return (
    <>
      <Wrapper>
        <MainTitle>{titolo}</MainTitle>
        <Testo
          renderers={{
            heading: HeadingRenderer,
            link: LinkRenderer,
          }}
          children={testo}
        />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.section`
  display: grid;
  min-width: 100%;
  grid-template-columns: 1fr 2fr;
  grid-template-areas: "testo cards";
  margin: 0 auto;
  padding: 10rem 4rem 6rem 10rem;
  background: ${COLORS.lightBlue};
  @media ${QUERY.mediumLarge} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${QUERY.medium} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem;
  }

  @media ${QUERY.small} {
    padding: 4rem 2rem;
  }
`

const MainTitle = styled.h1`
  background-color: ${COLORS.white};
  color: ${COLORS.black};
  padding: 4rem;
  width: 100%;
  font-size: calc(2 * var(--baseline-size));
  font-weight: 100;
  text-align: center;

  @media ${QUERY.large} {
    margin-top: 0;
  }

  @media ${QUERY.medium} {
    text-align: center;
  }

  @media ${QUERY.small} {
    font-size: calc(1.2 * var(--baseline-size));
  }
`

const Testo = styled(Markdown)`
  padding: 2rem;
  font-size: calc(1 * var(--baseline-size));
  font-weight: 300;
  @media ${QUERY.mediumSmall} {
    font-size: calc(0.9 * var(--baseline-size));
    padding: 1rem 0.5rem;
  }
`

export default Cookie
